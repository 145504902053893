import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MemberList from './components/MemberProfile/MemberList';
import MemberForm from './components/MemberProfile/MemberForm';
import MemberLog from './components/MemberLog/LogList';
import LogForm from './components/MemberLog/LogForm';
import TrialResidenceForm from './components/TrialResident/TrialResidenceForm';
import TrialResidenceList from './components/TrialResident/TrialResidenceList';
import EditTrialResidenceForm from './components/TrialResident/EditTrialResidenceForm';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import './App.css';

const App = ({ keycloak }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      keycloak.updateToken(70).then(refreshed => {
        if (refreshed) {
          console.log('Token refreshed', keycloak.token);
        } else {
          console.warn('Token not refreshed, valid for', Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000), 'seconds');
        }
      }).catch(() => {
        console.error('Failed to refresh token');
      });
    }, 60000);

    return () => clearInterval(refreshInterval);
  }, [keycloak]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Router>
      <div className={`app-container ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Navbar keycloak={keycloak} toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
        {sidebarOpen && <Sidebar />}
        <div className="content">
          <Routes>
            <Route path="/member-profile" element={<MemberList keycloak={keycloak} />} />
            <Route path="/add-member" element={<MemberForm onSave={() => window.location.href = '/member-profile'} keycloak={keycloak} />} />
            <Route path="/edit-member/:id" element={<MemberForm onSave={() => window.location.href = '/member-profile'} keycloak={keycloak} />} />
            <Route path="/member-log" element={<MemberLog keycloak={keycloak} />} />
            <Route path="/add-log" element={<LogForm onSave={() => window.location.href = '/member-log'} keycloak={keycloak} />} />
            <Route path="/edit-log/:id" element={<LogForm onSave={() => window.location.href = '/member-log'} keycloak={keycloak} />} />
            <Route path="/add-trial-residence" element={<TrialResidenceForm keycloak={keycloak} />} />
            <Route path="/trial-residences" element={<TrialResidenceList keycloak={keycloak} />} />
            <Route path="/trial-residence/add" element={<TrialResidenceForm keycloak={keycloak} />} />
            <Route path="/trial-residence/edit/:id" element={<EditTrialResidenceForm keycloak={keycloak} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
