import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import { useParams, useNavigate } from 'react-router-dom'; // 获取URL参数和用于跳转
import './TrialResidenceForm.css';

const EditTrialResidenceForm = ({ keycloak }) => {
  const { id } = useParams(); // 获取 URL 中的 id 参数
  const [visitorName, setVisitorName] = useState(''); // 访客姓名
  const [birthDate, setBirthDate] = useState(''); // 出生日期
  const [birthPlace, setBirthPlace] = useState(''); // 户籍地
  const [trialStartDate, setTrialStartDate] = useState(''); // 试入住开始日期
  const [trialEndDate, setTrialEndDate] = useState(''); // 试入住结束日期
  const [roomNumber, setRoomNumber] = useState(''); // 房号
  const [status, setStatus] = useState('待入住'); // 入住状态
  const [secretary, setSecretary] = useState(''); // 秘书
  const [secretaryFollowUp, setSecretaryFollowUp] = useState(''); // 秘书跟进记录
  const navigate = useNavigate(); // 用于页面跳转

  // 加载当前记录信息
  useEffect(() => {
    const fetchTrialResidence = async () => {
      try {
        const response = await axiosInstance.get(`/trial-residence/${id}`);
        const trialResidence = response.data;

        // 设置表单的初始值，确保字段有值，如果没有，则使用空字符串
        setVisitorName(trialResidence.visitorName || ''); // 访客姓名可以修改
        setBirthDate(trialResidence.birthDate || ''); // 出生日期
        setBirthPlace(trialResidence.birthPlace || ''); // 户籍地
        setTrialStartDate(trialResidence.trialStartDate || '');
        setTrialEndDate(trialResidence.trialEndDate || '');
        setRoomNumber(trialResidence.roomNumber || '');
        setStatus(trialResidence.status || '待入住');
        setSecretary(trialResidence.secretary || '');
        setSecretaryFollowUp(trialResidence.secretaryFollowUp || ''); // 秘书跟进记录
      } catch (error) {
        console.error('Error fetching trial residence:', error);
      }
    };

    fetchTrialResidence();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const postData = {
        visitorName: visitorName || '', // 确保字段不会为 null
        birthDate: birthDate || '',
        birthPlace: birthPlace || '', // 确保户籍地字段不为空
        trialStartDate: trialStartDate || '',
        trialEndDate: trialEndDate || '',
        roomNumber: roomNumber || '',
        status: status || '待入住',
        secretary: secretary || '',
        secretaryFollowUp: secretaryFollowUp || '', // 确保跟进记录不为空
      };

      await axiosInstance.put(`/trial-residence/${id}`, postData); // 更新试入住记录
      alert('记录已更新');
      navigate('/trial-residences'); // 跳转到记录列表页面
    } catch (error) {
      console.error('Error updating trial residence:', error);
    }
  };

  return (
    <div className="trial-residence-form">
      <h2>编辑试入住记录</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>访客姓名:</label>
          <input
            type="text"
            value={visitorName}
            onChange={(e) => setVisitorName(e.target.value)} // 允许修改访客姓名
            required
          />
        </div>
        <div className="form-group">
          <label>出生日期:</label>
          <input
            type="date"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)} // 允许修改出生日期
            required
          />
        </div>
        <div className="form-group">
          <label>户籍地:</label>
          <input
            type="text"
            value={birthPlace}
            onChange={(e) => setBirthPlace(e.target.value)} // 允许修改户籍地
          />
        </div>
        <div className="form-group">
          <label>试入住开始日期:</label>
          <input
            type="date"
            value={trialStartDate}
            onChange={(e) => setTrialStartDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>试入住结束日期:</label>
          <input
            type="date"
            value={trialEndDate}
            onChange={(e) => setTrialEndDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>房号:</label>
          <input
            type="text"
            value={roomNumber}
            onChange={(e) => setRoomNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>入住状态:</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="待入住">待入住</option>
            <option value="已入住">已入住</option>
            <option value="已退房">已退房</option>
          </select>
        </div>
        <div className="form-group">
          <label>秘书:</label>
          <input
            type="text"
            value={secretary}
            onChange={(e) => setSecretary(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>秘书跟进记录:</label>
          <textarea
            value={secretaryFollowUp}
            onChange={(e) => setSecretaryFollowUp(e.target.value)} // 允许修改跟进记录
          />
        </div>
        <button type="submit">保存更改</button>
      </form>
    </div>
  );
};

export default EditTrialResidenceForm;
