import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  const [memberProfileOpen, setMemberProfileOpen] = useState(false);
  const [memberLogOpen, setMemberLogOpen] = useState(false);
  const [trialResidenceOpen, setTrialResidenceOpen] = useState(false);

  const toggleMemberProfile = () => setMemberProfileOpen(!memberProfileOpen);
  const toggleMemberLog = () => setMemberLogOpen(!memberLogOpen);
  const toggleTrialResidence = () => setTrialResidenceOpen(!trialResidenceOpen);

  return (
    <div className="sidebar">
      <ul>
        <li>
          <div className="menu-item" onClick={toggleMemberProfile}>
            <span>会员档案</span>
            <span className={`arrow ${memberProfileOpen ? 'open' : ''}`}></span>
          </div>
          {memberProfileOpen && (
            <ul className="submenu">
              <li>
                <NavLink to="/member-profile" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-member" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className="menu-item" onClick={toggleMemberLog}>
            <span>会员日志</span>
            <span className={`arrow ${memberLogOpen ? 'open' : ''}`}></span>
          </div>
          {memberLogOpen && (
            <ul className="submenu">
              <li>
                <NavLink to="/member-log" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-log" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className="menu-item" onClick={toggleTrialResidence}>
            <span>试入住管理</span>
            <span className={`arrow ${trialResidenceOpen ? 'open' : ''}`}></span>
          </div>
          {trialResidenceOpen && (
            <ul className="submenu">
              <li>
                <NavLink to="/trial-residences" className={({ isActive }) => (isActive ? 'active' : '')}>
                  查询
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-trial-residence" className={({ isActive }) => (isActive ? 'active' : '')}>
                  录入
                </NavLink>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
