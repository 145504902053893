import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../axiosConfig'; // 使用配置好的 axios 实例
import './MemberForm.css';

const MemberForm = ({ onSave, keycloak }) => {
  const { id } = useParams();
  const [member, setMember] = useState({
    roomNumber: '',
    name: '',
    gender: '',
    contactNumber: '',
    checkInDate: '',
    idNumber: '',
    birthDate: '',
    household: '',
    education: '',
    maritalStatus: '',
    children: '',
    insuranceLevel: '',
    hasEmergencyContact: false,
    emergencyContact: '',
    emergencyRelation: '',
    emergencyPhone: '',
    hobbies: '',
    taboos: ''
  });

  const fetchMember = useCallback(async () => {
    if (id) {
      try {
        if (keycloak) {
          await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
          const response = await axios.get(`/members/${id}`, {
            headers: {
              Authorization: `Bearer ${keycloak.token}`
            }
          });
          const data = response.data;
          // Ensure dates are in the correct format (YYYY-MM-DD)
          data.birthDate = data.birthDate ? data.birthDate.split('T')[0] : '';
          data.checkInDate = data.checkInDate ? data.checkInDate.split('T')[0] : '';
          setMember(data);
        }
      } catch (error) {
        console.error('Error fetching member:', error);
      }
    }
  }, [id, keycloak]);

  useEffect(() => {
    fetchMember();
  }, [fetchMember]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMember({
      ...member,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setMember({
      ...member,
      [name]: checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (keycloak) {
        await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
        if (id) {
          await axios.put(`/members/${id}`, member, {
            headers: {
              Authorization: `Bearer ${keycloak.token}`
            }
          });
        } else {
          await axios.post('/members/add', member, {
            headers: {
              Authorization: `Bearer ${keycloak.token}`
            }
          });
        }
        onSave();
      }
    } catch (error) {
      console.error('Error saving member:', error);
    }
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <div>
        <label>房号:</label>
        <input type="text" name="roomNumber" value={member.roomNumber} onChange={handleChange} required />
      </div>
      <div>
        <label>姓名:</label>
        <input type="text" name="name" value={member.name} onChange={handleChange} required />
      </div>
      <div>
        <label>性别:</label>
        <select name="gender" value={member.gender} onChange={handleChange} required>
          <option value="">请选择</option>
          <option value="男">男</option>
          <option value="女">女</option>
        </select>
      </div>
      <div>
        <label>联系电话:</label>
        <input type="text" name="contactNumber" value={member.contactNumber} onChange={handleChange} required />
      </div>
      <div>
        <label>入住日期:</label>
        <input type="date" name="checkInDate" value={member.checkInDate} onChange={handleChange} required />
      </div>
      <div>
        <label>身份证号:</label>
        <input type="text" name="idNumber" value={member.idNumber} onChange={handleChange} required />
      </div>
      <div>
        <label>出生日期:</label>
        <input type="date" name="birthDate" value={member.birthDate} onChange={handleChange} required />
      </div>
      <div>
        <label>家庭住址:</label>
        <input type="text" name="household" value={member.household} onChange={handleChange} required />
      </div>
      <div>
        <label>文化程度:</label>
        <select name="education" value={member.education} onChange={handleChange} required>
          <option value="">请选择</option>
          <option value="初中及以下">初中及以下</option>
          <option value="高中">高中</option>
          <option value="大专">大专</option>
          <option value="本科">本科</option>
          <option value="硕士研究生及以上">硕士研究生及以上</option>
        </select>
      </div>
      <div>
        <label>婚姻状况:</label>
        <select name="maritalStatus" value={member.maritalStatus} onChange={handleChange} required>
          <option value="">请选择</option>
          <option value="已婚">已婚</option>
          <option value="未婚">未婚</option>
          <option value="离异">离异</option>
          <option value="丧偶">丧偶</option>
          <option value="再婚">再婚</option>
        </select>
      </div>
      <div>
        <label>子女情况:</label>
        <input type="text" name="children" value={member.children} onChange={handleChange} />
      </div>
      <div>
        <label>长护险等级:</label>
        <select name="insuranceLevel" value={member.insuranceLevel} onChange={handleChange} required>
          <option value="">请选择</option>
          <option value="无">无</option>
          <option value="1级">1级</option>
          <option value="2级">2级</option>
          <option value="3级">3级</option>
          <option value="4级">4级</option>
          <option value="5级">5级</option>
          <option value="6级">6级</option>
        </select>
      </div>
      <div>
        <label>有无紧急联系人:</label>
        <input type="checkbox" name="hasEmergencyContact" checked={member.hasEmergencyContact} onChange={handleCheckboxChange} />
      </div>
      {member.hasEmergencyContact && (
        <>
          <div>
            <label>紧急联系人:</label>
            <input type="text" name="emergencyContact" value={member.emergencyContact} onChange={handleChange} />
          </div>
          <div>
            <label>紧急联系电话:</label>
            <input type="text" name="emergencyPhone" value={member.emergencyPhone} onChange={handleChange} />
          </div>
          <div>
            <label>紧急联系人关系:</label>
            <input type="text" name="emergencyRelation" value={member.emergencyRelation} onChange={handleChange} />
          </div>
        </>
      )}
      <div>
        <label>爱好:</label>
        <input type="text" name="hobbies" value={member.hobbies} onChange={handleChange} />
      </div>
      <div>
        <label>禁忌事项:</label>
        <input type="text" name="taboos" value={member.taboos} onChange={handleChange} />
      </div>
      <button type="submit">保存</button>
    </form>
  );
};

export default MemberForm;
