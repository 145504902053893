import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig'; // 使用配置好的 axios 实例
import './LogList.css';

const LogList = ({ keycloak }) => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedLog, setSelectedLog] = useState(null);
  const [logToDelete, setLogToDelete] = useState(null);
  const [inputPage, setInputPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [recorders, setRecorders] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedRecorder, setSelectedRecorder] = useState('');
  const [startDate, setStartDate] = useState(''); // 新增
  const [endDate, setEndDate] = useState(''); // 新增
  const navigate = useNavigate();

  const fetchLogs = useCallback(async () => {
    try {
      await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
      const response = await axios.get('/logs', {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        },
        params: {
          page: page - 1,
          size: 15,
          search: search,
          category: selectedCategory,
          recorder: selectedRecorder,
          startDate: startDate || null, // 新增
          endDate: endDate || null // 新增
        },
      });
      setLogs(response.data.content);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalElements);
      setInputPage(page);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  }, [page, search, selectedCategory, selectedRecorder, startDate, endDate, keycloak]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  const handleDateChange = (e, type) => {
    if (type === 'start') {
      setStartDate(e.target.value);
    } else if (type === 'end') {
      setEndDate(e.target.value);
    }
  };

  const fetchCategories = useCallback(async () => {
    try {
      await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
      const response = await axios.get('/logs/categories', {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        }
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }, [keycloak]);

  const fetchRecorders = useCallback(async () => {
    try {
      await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
      const response = await axios.get('/logs/recorders', {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        }
      });
      setRecorders(response.data);
    } catch (error) {
      console.error('Error fetching recorders:', error);
    }
  }, [keycloak]);

  useEffect(() => {
    fetchCategories();
    fetchRecorders();
  }, [fetchCategories, fetchRecorders]);

  const handleDelete = async (id) => {
    try {
      await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
      await axios.delete(`/logs/${id}`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        }
      });
      fetchLogs();
      setLogToDelete(null);
    } catch (error) {
      console.error('Error deleting log:', error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-log/${id}`);
  };

  const handleCloseModal = () => {
    setSelectedLog(null);
  };

  const handleConfirmDelete = (log) => {
    setLogToDelete(log);
  };

  const handleCancelDelete = () => {
    setLogToDelete(null);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleJumpToPage = () => {
    handlePageChange(inputPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 4);
    let endPage = Math.min(totalPages, page + 5);

    if (endPage - startPage < 9) {
      startPage = Math.max(1, endPage - 9);
      endPage = Math.min(totalPages, startPage + 9);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={i === page ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  // 新增函数：获取所有日志数据
  const fetchAllLogs = async () => {
    try {
      await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
      const response = await axios.get('/logs/all', { // 假设后端提供一个接口可以获取所有日志
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        },
        params: {
          search: search,
          category: selectedCategory,
          recorder: selectedRecorder,
          startDate: startDate || null,
          endDate: endDate || null
        }
      });
      return response.data; // 返回所有日志数据
    } catch (error) {
      console.error('Error fetching all logs:', error);
      return [];
    }
  };

  // 修改后的导出CSV功能
  const exportToCSV = async () => {
    const allLogs = await fetchAllLogs(); // 获取所有日志数据
    const csvHeaders = ["房号", "姓名", "记录日期", "类别", "记录人", "内容"];
  
    // 确保日志数据正确转换为CSV格式，处理可能的特殊字符
    const csvRows = allLogs.map(log => [
      log.roomNumber,
      log.name,
      log.recordDate,
      log.category,
      log.recorder,
      `"${log.content.replace(/"/g, '""')}"` // 替换双引号并处理内容中的换行符
    ]);
  
    // 创建CSV字符串，确保每一行都正确加入
    const csvContent = [csvHeaders, ...csvRows].map(e => e.join(",")).join("\n");
  
    // 检查生成的CSV内容
    console.log(csvContent); // Debug: 打印CSV内容到控制台
  
    // 创建Blob对象并下载
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "logs.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="log-list">
      <div className="filters">
        <input
          type="text"
          placeholder="搜索..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
          <option value="">所有类别</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
        <select value={selectedRecorder} onChange={(e) => setSelectedRecorder(e.target.value)}>
          <option value="">所有记录人</option>
          {recorders.map((recorder, index) => (
            <option key={index} value={recorder}>{recorder}</option>
          ))}
        </select>
        <input
          type="date"
          value={startDate}
          onChange={(e) => handleDateChange(e, 'start')}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => handleDateChange(e, 'end')}
        />
        {/* 新增导出按钮 */}
        <button onClick={exportToCSV}>导出数据</button>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>房号</th>
              <th>姓名</th>
              <th>记录日期</th>
              <th>类别</th>
              <th>记录人</th>
              <th>内容</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log.id}>
                <td>{log.roomNumber}</td>
                <td>{log.name}</td>
                <td>{log.recordDate}</td>
                <td>{log.category}</td>
                <td>{log.recorder}</td>
                <td className="content-cell">{log.content}</td>
                <td>
                  <button onClick={() => handleEdit(log.id)}>编辑</button>
                  <button onClick={() => handleConfirmDelete(log)}>删除</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <div className="pagination">
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
            上一页
          </button>
          {renderPageNumbers()}
          <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
            下一页
          </button>
        </div>
        <div className="pagination-info">
          <span>共 {totalItems} 条记录, {totalPages} 页</span>
          <div className="page-jump">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={inputPage}
              onChange={(e) => setInputPage(Number(e.target.value))}
            />
            <button onClick={handleJumpToPage}>跳转</button>
          </div>
        </div> 
      </div>
      {selectedLog && (
        <div className="modal">
          <div className="modal-content">
            <h2>详细内容</h2>
            <div className="modal-body">
              <p>{selectedLog.content}</p>
            </div>
            <button className="modal-close" onClick={handleCloseModal}>关闭</button>
          </div>
        </div>
      )}
      {logToDelete && (
        <div className="modal">
          <div className="modal-content">
            <h2>确认删除</h2>
            <p>确定要删除此日志吗？</p>
            <button onClick={() => handleDelete(logToDelete.id)}>确认</button>
            <button onClick={handleCancelDelete}>取消</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogList;
