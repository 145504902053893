import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../axiosConfig';
import './TrialResidenceList.css';
import { useNavigate } from 'react-router-dom';

const TrialResidenceList = ({ keycloak }) => {
  const [trialResidences, setTrialResidences] = useState([]);
  const [search, setSearch] = useState(''); // 用于访客姓名或房号的搜索
  const [planners, setPlanners] = useState([]); // 筛选规划师
  const [secretaries, setSecretaries] = useState([]); // 筛选秘书
  const [selectedPlanner, setSelectedPlanner] = useState('');
  const [selectedSecretary, setSelectedSecretary] = useState('');
  const [startDate, setStartDate] = useState(''); // 筛选起始日期
  const [endDate, setEndDate] = useState(''); // 筛选结束日期
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [selectedResidence, setSelectedResidence] = useState(null); // 用于获取 residence
  const [isVisitorInfoOpen, setIsVisitorInfoOpen] = useState(false);
  const [page, setPage] = useState(1); // 当前页
  const [totalPages, setTotalPages] = useState(0); // 总页数
  const [totalItems, setTotalItems] = useState(0); // 总条数
  const [inputPage, setInputPage] = useState(1); // 输入跳转的页数

  const navigate = useNavigate();

  // 获取试入住记录数据
  const fetchTrialResidences = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/trial-residence', {
        params: {
          search,
          planner: selectedPlanner,
          secretary: selectedSecretary,
          startDate,
          endDate,
          page: page - 1, // 当前页码
          size: 10, // 每页大小
        }
      });
      setTrialResidences(response.data.content);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalElements);
      setInputPage(page);
    } catch (error) {
      console.error('Error fetching trial residences:', error);
    }
  }, [search, selectedPlanner, selectedSecretary, startDate, endDate, page]);

  useEffect(() => {
    fetchTrialResidences();
  }, [fetchTrialResidences]);

  // 获取所有规划师和秘书以供筛选
  const fetchFilters = useCallback(async () => {
    try {
      const plannersResponse = await axiosInstance.get('/visitors/planners');
      setPlanners(plannersResponse.data);

      const secretariesResponse = await axiosInstance.get('/trial-residence/secretaries');
      setSecretaries(secretariesResponse.data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }, []);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  // 删除记录的函数
  const handleDelete = async (id) => {
    if (window.confirm('确认删除该记录吗？')) {
      try {
        await axiosInstance.delete(`/trial-residence/${id}`);
        alert('记录已删除');
        setTrialResidences(trialResidences.filter((residence) => residence.id !== id));
      } catch (error) {
        console.error('Error deleting record:', error);
      }
    }
  };

  // 编辑记录的函数
  const handleEdit = (id) => {
    navigate(`/trial-residence/edit/${id}`);
  };

  // 获取访客信息以及试入住记录
  const handleViewVisitor = async (visitorId) => {
    try {
      // 获取访客信息
      const visitorResponse = await axiosInstance.get(`/visitors/${visitorId}`);
      setSelectedVisitor(visitorResponse.data);
  
      // 获取试入住记录，根据 visitorId 从后端获取对应的记录
      const residenceResponse = await axiosInstance.get(`/trial-residence/visitor/${visitorId}`);
      setSelectedResidence(residenceResponse.data);
  
      setIsVisitorInfoOpen(true);
    } catch (error) {
      console.error('Error fetching visitor or residence info:', error);
    }
  };


  // 关闭访客信息的对话框
  const closeVisitorInfo = () => {
    setIsVisitorInfoOpen(false);
    setSelectedVisitor(null);
    setSelectedResidence(null);
  };

  // 分页相关功能
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleJumpToPage = () => {
    handlePageChange(inputPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 4);
    let endPage = Math.min(totalPages, page + 5);

    if (endPage - startPage < 9) {
      startPage = Math.max(1, endPage - 9);
      endPage = Math.min(totalPages, startPage + 9);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={i === page ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="trial-residence-list">
      <h2>试入住记录</h2>

      {/* 添加搜索和筛选条件 */}
      <div className="filters">
        <input
          type="text"
          placeholder="搜索访客姓名或房号..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <select value={selectedPlanner} onChange={(e) => setSelectedPlanner(e.target.value)}>
          <option value="">所有规划师</option>
          {planners.map((planner, index) => (
            <option key={index} value={planner}>{planner}</option>
          ))}
        </select>

        <select value={selectedSecretary} onChange={(e) => setSelectedSecretary(e.target.value)}>
          <option value="">所有秘书</option>
          {secretaries.map((secretary, index) => (
            <option key={index} value={secretary}>{secretary}</option>
          ))}
        </select>

        <input
          type="date"
          placeholder="起始日期"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />

        <input
          type="date"
          placeholder="结束日期"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>访客姓名</th>
              <th>规划师</th>
              <th>秘书</th>
              <th>试入住开始日期</th>
              <th>试入住结束日期</th>
              <th>房号</th>
              <th>入住状态</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {trialResidences.map((residence) => (
              <tr key={residence.id}>
                <td>{residence.visitorName}</td>
                <td>{residence.planner}</td>
                <td>{residence.secretary}</td>
                <td>{residence.trialStartDate}</td>
                <td>{residence.trialEndDate}</td>
                <td>{residence.roomNumber}</td>
                <td>{residence.status}</td>
                <td>
                  <button onClick={() => handleViewVisitor(residence.visitorId)}>查看访客信息</button>
                  <button onClick={() => handleEdit(residence.id)}>编辑</button>
                  <button onClick={() => handleDelete(residence.id)}>删除</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <div className="pagination">
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
            上一页
          </button>
          {renderPageNumbers()}
          <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
            下一页
          </button>
        </div>
        <div className="pagination-info">
          <span>共 {totalItems} 条记录, {totalPages} 页</span>
          <div className="page-jump">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={inputPage}
              onChange={(e) => setInputPage(Number(e.target.value))}
            />
            <button onClick={handleJumpToPage}>跳转</button>
          </div>
        </div>
      </div>

      {isVisitorInfoOpen && selectedVisitor && selectedResidence && (
        <div className="modal">
          <div className="modal-content">
            <h3>访客信息</h3>
            <div className="modal-body">
              {/* Visitor 信息 */}
              <p><strong>姓名：</strong>{selectedVisitor.name}</p>
              <p><strong>性别：</strong>{selectedVisitor.gender}</p>
              <p><strong>出生日期：</strong>{selectedResidence.birthDate ? selectedResidence.birthDate : '无数据'}</p>
              <p><strong>户籍地：</strong>{selectedResidence.birthPlace ? selectedResidence.birthPlace : '无数据'}</p>
              <p><strong>电话：</strong>{selectedVisitor.phone}</p>
              <p><strong>预约电话：</strong>{selectedVisitor.appointmentPhone}</p>
              <p><strong>职业：</strong>{selectedVisitor.occupation}</p>
              <p><strong>微信：</strong>{selectedVisitor.wechat}</p>
              <p><strong>居住状态：</strong>{selectedVisitor.residenceStatus}</p>
              <p><strong>项目组：</strong>{selectedVisitor.projectGroup}</p>
              <p><strong>来访方式：</strong>{selectedVisitor.visitMethod}</p>
              <p><strong>居住城市：</strong>{selectedVisitor.residenceCity}</p>
              <p><strong>居住区域：</strong>{selectedVisitor.residenceArea}</p>
              <p><strong>居住详情：</strong>{selectedVisitor.residenceDetail}</p>
              <p><strong>意向楼栋：</strong>{selectedVisitor.intendedBuilding}</p>
              <p><strong>意向卡类型：</strong>{selectedVisitor.intendedCardType}</p>
              <p><strong>意向房型：</strong>{selectedVisitor.intendedRoomType}</p>
              <p><strong>收入和资产：</strong>{selectedVisitor.incomeAssets}</p>
              <p><strong>子女情况：</strong>{selectedVisitor.children}</p>
              <p><strong>客户关注点：</strong>{selectedVisitor.customerConcerns}</p>
              <p><strong>客户抗性：</strong>{selectedVisitor.customerResistance}</p>
              <p><strong>来访次数：</strong>{selectedVisitor.visitTimes}</p>
              <p><strong>客户等级：</strong>{selectedVisitor.customerLevel}</p>
              <p><strong>渠道联系人：</strong>{selectedVisitor.channelContact}</p>
              <p><strong>跟进状态：</strong>{selectedVisitor.followUpStatus}</p>
              <p><strong>客户状态：</strong>{selectedVisitor.customerStatus}</p>
              <p><strong>跟进详情：</strong>{selectedVisitor.followUpDetails}</p>
              <p><strong>备注：</strong>{selectedVisitor.remarks}</p>
              <p><strong>秘书跟进记录：</strong>{selectedResidence.secretaryFollowUp ? selectedResidence.secretaryFollowUp : '无数据'}</p>
            </div>
            <button onClick={closeVisitorInfo}>关闭</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrialResidenceList;
