// src/components/UserMenu.js
import React, { useState, useRef, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import './UserMenu.css';

const UserMenu = ({ keycloak }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleLogout = () => {
    keycloak.logout();
  };

  const handleEditProfile = () => {
    window.location.href = 'https://login.qinhegu.cn/realms/qinhegu/account';
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="user-menu" ref={menuRef}>
      <span onClick={toggleMenu} className="user-name">
        <FaUserCircle className="user-icon" />
        <span className="username-text">{keycloak.tokenParsed?.family_name}</span>
        <span className="username-text">{keycloak.tokenParsed?.given_name}</span>
      </span>
      {menuOpen && (
        <div className="dropdown-menu">
          <button onClick={handleEditProfile}>编辑资料</button>
          <button onClick={handleLogout}>登出</button>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
