import React from 'react';
import UserMenu from './components/UserMenu';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import logo from './images/logo.png'; // 请将路径替换为你的Logo图片路径

const Navbar = ({ keycloak, toggleSidebar, sidebarOpen }) => {
  return (
    <div className="navbar">
      <div className="navbar-left">
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          {sidebarOpen ? <FaTimes /> : <FaBars />}
        </button>
        <div className="navbar-brand">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </div>
      <div className="navbar-menu">
        <UserMenu keycloak={keycloak} />
      </div>
    </div>
  );
};

export default Navbar;
