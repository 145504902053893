import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig'; // Make sure axiosInstance is imported correctly
import './TrialResidenceForm.css';
import { format } from 'date-fns'; // Importing date-fns for date formatting
import { useNavigate } from 'react-router-dom'; // Importing useNavigate hook

const TrialResidenceForm = ({ keycloak }) => {
  const [visitors, setVisitors] = useState([]); // List of visitors
  const [planners, setPlanners] = useState([]); // List of planners
  const [selectedVisitorId, setSelectedVisitorId] = useState(''); // Selected visitor ID
  const [visitorName, setVisitorName] = useState(''); // Visitor name
  const today = format(new Date(), 'yyyy-MM-dd');
  const threeDaysLater = format(new Date(Date.now() + 3 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
  const [trialStartDate, setTrialStartDate] = useState(today); // Trial start date
  const [trialEndDate, setTrialEndDate] = useState(threeDaysLater); // Trial end date
  const [filterDate, setFilterDate] = useState(''); // Date filter
  const [filterPlanner, setFilterPlanner] = useState(''); // Planner filter
  const [secretary, setSecretary] = useState(''); // Secretary field
  const [roomNumber, setRoomNumber] = useState(''); // Room number
  const [status, setStatus] = useState('待入住'); // Default status
  const [birthDate, setBirthDate] = useState('1950-01-01'); // Birth date
  const [birthPlace, setBirthPlace] = useState(''); // Birthplace
  const [secretaryFollowUp, setSecretaryFollowUp] = useState(''); // Secretary follow-up notes
  const [searchKeyword, setSearchKeyword] = useState(''); // Search keyword

  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchPlanners = async () => {
      try {
        const response = await axiosInstance.get('/visitors/planners');
        setPlanners(response.data);
      } catch (error) {
        console.error('Error fetching planners:', error);
      }
    };

    fetchPlanners();
  }, []);

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        let formattedDate = filterDate ? format(new Date(filterDate), 'yyyy-MM-dd') : undefined;

        const response = await axiosInstance.get('/visitors', {
          params: {
            visit_time: formattedDate || undefined,
            planner: filterPlanner || undefined,
            search: searchKeyword || undefined, // Adding search keyword for visitor filtering
          },
        });

        setVisitors(response.data); // Update visitor list
      } catch (error) {
        console.error('Error fetching visitors:', error);
      }
    };

    fetchVisitors();
  }, [filterDate, filterPlanner, searchKeyword]);

  useEffect(() => {
    if (keycloak && keycloak.tokenParsed) {
      const userFullName = keycloak.tokenParsed.family_name + keycloak.tokenParsed.given_name;
      setSecretary(userFullName); // Set default secretary name
    }
  }, [keycloak]);

  const handleVisitorChange = (e) => {
    const visitorId = e.target.value;
    setSelectedVisitorId(visitorId);

    const selectedVisitor = visitors.find((visitor) => visitor.id === parseInt(visitorId));
    if (selectedVisitor) {
      setVisitorName(selectedVisitor.name);
    }
  };

  const checkDuplicate = async (visitorId) => {
    try {
      const response = await axiosInstance.post('/trial-residence/check-duplicate', { visitorId });
      return response.data.exists;
    } catch (error) {
      console.error('Error checking duplicate:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isDuplicate = await checkDuplicate(selectedVisitorId);
      if (isDuplicate) {
        alert('该访客的试入住记录已经存在，不能重复提交。');
        return;
      }

      const postData = {
        visitorId: selectedVisitorId,
        visitorName,
        trialStartDate,
        trialEndDate,
        secretary,
        roomNumber,
        status,
        secretaryFollowUp: secretaryFollowUp || null,
        birthDate: birthDate || null,
        birthPlace: birthPlace || null
      };

      console.log("Sending data to backend:", postData);

      await axiosInstance.post('/trial-residence/add', postData);

      alert('试入住记录已成功添加');
      navigate('/trial-residences');
    } catch (error) {
      console.error('Error adding trial residence:', error);
      alert('提交失败，请稍后再试。');
    }
  };

  return (
    <div className="trial-residence-form">
      <h2>添加试入住记录</h2>
      <form onSubmit={handleSubmit}>
        <div className="filter-container">
          <input
            type="date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
          <select
            value={filterPlanner}
            onChange={(e) => setFilterPlanner(e.target.value)}
          >
            <option value="">所有规划师</option>
            {planners.map((planner, index) => (
              <option key={index} value={planner}>
                {planner}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="搜索访客"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>选择访客:</label>
          <select
            value={selectedVisitorId || ''}
            onChange={handleVisitorChange}
            required
          >
            <option value="">请选择访客</option>
            {visitors.map((visitor) => (
              <option key={visitor.id} value={visitor.id}>
                {visitor.name} - {format(new Date(visitor.visitTime), 'yyyy-MM-dd')} - {visitor.planner}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>访客姓名:</label>
          <input
            type="text"
            value={visitorName}
            onChange={(e) => setVisitorName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>试入住开始日期:</label>
          <input
            type="date"
            value={trialStartDate}
            onChange={(e) => setTrialStartDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>试入住结束日期:</label>
          <input
            type="date"
            value={trialEndDate}
            onChange={(e) => setTrialEndDate(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>房号:</label>
          <input
            type="text"
            value={roomNumber}
            onChange={(e) => setRoomNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>入住状态:</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value="待入住">待入住</option>
            <option value="已入住">已入住</option>
            <option value="已退房">已退房</option>
          </select>
        </div>
        <div className="form-group">
          <label>秘书:</label>
          <input
            type="text"
            value={secretary}
            onChange={(e) => setSecretary(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>出生日期:</label>
          <input
            type="date"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>户籍地:</label>
          <input
            type="text"
            value={birthPlace}
            onChange={(e) => setBirthPlace(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>秘书跟进记录:</label>
          <textarea
            value={secretaryFollowUp}
            onChange={(e) => setSecretaryFollowUp(e.target.value)}
          />
        </div>
        <button type="submit">保存试入住记录</button>
      </form>
    </div>
  );
};

export default TrialResidenceForm;
