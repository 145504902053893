import React from 'react';
import ReactDOM from 'react-dom/client'; // 更新导入路径
import App from './App';
import keycloak from './keycloak'; // 从 keycloak.js 导入已经初始化的 keycloak 实例

keycloak.init({ onLoad: 'login-required' })
  .then(authenticated => {
    if (authenticated) {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <React.StrictMode>
          <App keycloak={keycloak} />
        </React.StrictMode>
      );
    } else {
      console.warn('User is not authenticated');
      window.location.reload();
    }
  })
  .catch(error => {
    console.error('Failed to initialize Keycloak:', error);
  });
