import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig'; // 使用配置好的 axios 实例
import './MemberList.css';

const MemberList = ({ keycloak }) => {
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedMember, setSelectedMember] = useState(null);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [inputPage, setInputPage] = useState(1);
  const navigate = useNavigate();

  const fetchMembers = useCallback(async () => {
    try {
      await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
      const response = await axios.get('/members', {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        },
        params: {
          page: page - 1,
          size: 15,
          search: search,
        },
      });
      setMembers(response.data.content);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalElements);
      setInputPage(page);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  }, [page, search, keycloak]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const handleDelete = async (id) => {
    try {
      await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
      await axios.delete(`/members/${id}`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        }
      });
      fetchMembers();
      setMemberToDelete(null);
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-member/${id}`);
  };

  const handleView = (member) => {
    setSelectedMember(member);
  };

  const handleCloseModal = () => {
    setSelectedMember(null);
  };

  const handleConfirmDelete = (member) => {
    setMemberToDelete(member);
  };

  const handleCancelDelete = () => {
    setMemberToDelete(null);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleJumpToPage = () => {
    handlePageChange(inputPage);
  };

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, page - 4);
    let endPage = Math.min(totalPages, page + 5);

    if (endPage - startPage < 9) {
      startPage = Math.max(1, endPage - 9);
      endPage = Math.min(totalPages, startPage + 9);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={i === page ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="member-list">
      <div className="filters">
        <input
          type="text"
          placeholder="搜索..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>姓名</th>
              <th>房号</th>
              <th>性别</th>
              <th>年龄</th>
              <th>入住日期</th>
              <th>身份证号</th>
              <th>联系电话</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.id}>
                <td>{member.name}</td>
                <td>{member.roomNumber}</td>
                <td>{member.gender}</td>
                <td>{calculateAge(member.birthDate)}</td>
                <td>{member.checkInDate}</td>
                <td>{member.idNumber}</td>
                <td>{member.contactNumber}</td>
                <td>
                  <button onClick={() => handleView(member)}>查看详细</button>
                  <button onClick={() => handleEdit(member.id)}>编辑</button>
                  <button onClick={() => handleConfirmDelete(member)}>删除</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <div className="pagination">
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
            上一页
          </button>
          {renderPageNumbers()}
          <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
            下一页
          </button>
        </div>
        <div className="pagination-info">
          <span>共 {totalItems} 条记录, {totalPages} 页</span>
          <div className="page-jump">
            <input
              type="number"
              min="1"
              max={totalPages}
              value={inputPage}
              onChange={(e) => setInputPage(Number(e.target.value))}
            />
            <button onClick={handleJumpToPage}>跳转</button>
          </div>
        </div>
      </div>
      {selectedMember && (
        <div className="modal">
          <div className="modal-content">
            <h2>详细内容</h2>
            <div className="modal-body">
              <p>姓名: {selectedMember.name}</p>
              <p>房号: {selectedMember.roomNumber}</p>
              <p>性别: {selectedMember.gender}</p>
              <p>出生日期: {selectedMember.birthDate}</p>
              <p>入住日期: {selectedMember.checkInDate}</p>
              <p>身份证号: {selectedMember.idNumber}</p>
              <p>联系电话: {selectedMember.contactNumber}</p>
              <p>教育程度: {selectedMember.education}</p>
              <p>户籍: {selectedMember.household}</p>
              <p>婚姻状况: {selectedMember.maritalStatus}</p>
              <p>长护险等级: {selectedMember.insuranceLevel}</p>
              <p>子女: {selectedMember.children}</p>
              <p>紧急联系人: {selectedMember.emergencyContact}</p>
              <p>紧急联系电话: {selectedMember.emergencyPhone}</p>
              <p>紧急联系人关系: {selectedMember.emergencyRelation}</p>
              <p>有无紧急联系人: {selectedMember.hasEmergencyContact ? '是' : '否'}</p>
              <p>爱好: {selectedMember.hobbies}</p>
              <p>禁忌: {selectedMember.taboos}</p>
            </div>
            <button className="modal-close" onClick={handleCloseModal}>关闭</button>
          </div>
        </div>
      )}
      {memberToDelete && (
        <div className="modal">
          <div className="modal-content">
            <h2>确认删除</h2>
            <p>确定要删除此会员吗？</p>
            <button onClick={() => handleDelete(memberToDelete.id)}>确认</button>
            <button onClick={handleCancelDelete}>取消</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberList;
