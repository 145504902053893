import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../axiosConfig'; // 使用配置好的 axios 实例
import './LogForm.css';

const LogForm = ({ onSave, keycloak }) => {
  const { id } = useParams();
  const [log, setLog] = useState({
    roomNumber: '',
    name: '',
    recordDate: '',
    category: '',
    recorder: '', // This will be set automatically
    content: '',
  });

  const fetchLog = useCallback(async () => {
    if (id) {
      try {
        await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期
        const response = await axios.get(`/logs/${id}`, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`
          }
        });
        const data = response.data;
        // Ensure dates are in the correct format (YYYY-MM-DD)
        data.recordDate = data.recordDate ? data.recordDate.split('T')[0] : '';
        setLog(data);
      } catch (error) {
        console.error('Error fetching log:', error);
      }
    }
  }, [id, keycloak]);

  useEffect(() => {
    fetchLog();
  }, [fetchLog]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLog({
      ...log,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await keycloak.updateToken(5); // 刷新令牌，如果令牌在5秒内过期

      // Get recorder from JWT token
      const recorder = keycloak.tokenParsed.family_name + keycloak.tokenParsed.given_name;

      const logToSave = {
        ...log,
        recorder, // Automatically set the recorder field
      };

      if (id) {
        await axios.put(`/logs/${id}`, logToSave, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`
          }
        });
      } else {
        await axios.post('/logs/add', logToSave, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`
          }
        });
      }
      onSave();
    } catch (error) {
      console.error('Error saving log:', error);
    }
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <div>
        <label>房号:</label>
        <input type="text" name="roomNumber" value={log.roomNumber} onChange={handleChange} required />
      </div>
      <div>
        <label>姓名:</label>
        <input type="text" name="name" value={log.name} onChange={handleChange} required />
      </div>
      <div>
        <label>记录日期:</label>
        <input type="date" name="recordDate" value={log.recordDate} onChange={handleChange} required />
      </div>
      <div>
        <label>类别:</label>
        <select name="category" value={log.category} onChange={handleChange} required>
          <option value="">请选择</option>
          <option value="生活">生活</option>
          <option value="健康">健康</option>
        </select>
      </div>
      <div>
        <label>内容:</label>
        <textarea name="content" value={log.content} onChange={handleChange} required />
      </div>
      <button type="submit">保存</button>
    </form>
  );
};

export default LogForm;
